import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import './styles.scss';

const Intro = ({ data, style, innerRef }) => (
  <section className="page-intro">
    <div className="wrapper">
      <div className="page-intro-content" style={style} ref={innerRef}>
        {data.introList.introHeading ? (
          <div>{parse(data.introList.introHeading)}</div>
        ) : null}
        <div className="page-intro-flex-container">
          {Array.isArray(data.introList.introDescription)
            ? data.introList.introDescription.map((item) => (
                <div className="page-intro-flexbox" key={item.id}>
                  <div className="page-intro-flex-content">
                    {item.description ? (
                      <div>{parse(item.description)}</div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  </section>
);

Intro.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(Intro);
