/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';
import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';
import CarrierList from '../carrier-list';
import CarrierListCarousel from '../carrier-list-carousel';
import './styles.scss';
import { PrimaryButton } from '../elements/buttons';

import ContactContext from '../../context/ContactContext';
import Form from '../forms/contact-form';

const animateBlocks = () => {
  // Blocks on hero with input component
  const firstBlockInput = document.querySelector('.block-container .block-one');
  const secondBlockInput = document.querySelector(
    '.block-container .block-two'
  );
  const thirdBlockInput = document.querySelector(
    '.block-container .block-three'
  );
  const fourthBlockInput = document.querySelector(
    '.block-container .block-four'
  );

  if (document.body.scrollTop >= 0 || document.documentElement.scrollTop >= 0) {
    if (firstBlockInput) {
      firstBlockInput.style.transform = `translateX(${window.pageYOffset}px)`;
    }
    if (secondBlockInput) {
      secondBlockInput.style.transform = `translateX(${window.pageYOffset}px)`;
    }
    if (thirdBlockInput) {
      thirdBlockInput.style.transform = `translateX(-${window.pageYOffset}px)`;
    }
    if (fourthBlockInput) {
      fourthBlockInput.style.transform = `translateX(-${window.pageYOffset}px)`;
    }
  }
};

class HeroInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      suggestions: [],
      text: '',
      slug: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', animateBlocks);
    // eslint-disable-next-line max-len
    if (
      this.props.showForm ||
      this.props.showFormGeorgiaAgents ||
      this.props.showFormWashingtonAgents ||
      this.props.showFormPennsylvaniaAgents ||
      this.props.showFormArizonaAgents ||
      this.props.showFormArizonaAgents ||
      this.props.showFormWisconsinAgents ||
      this.props.showFormIndianaAgents ||
      this.props.showFormUtahAgents ||
      this.props.showFormOregonAgents ||
      this.props.showFormCaliforniaAgents
    ) {
      const script = document.createElement('script');
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'statePageForm'
        });
      `;
      document.head.appendChild(script);
      this.scriptElement = script;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', animateBlocks);
    if (
      this.props.showForm ||
      this.props.showFormGeorgiaAgents ||
      this.props.showFormWashingtonAgents ||
      this.props.showFormPennsylvaniaAgents ||
      this.props.showFormArizonaAgents ||
      this.props.showFormArizonaAgents ||
      this.props.showFormWisconsinAgents ||
      this.props.showFormIndianaAgents ||
      this.props.showFormUtahAgents ||
      this.props.showFormOregonAgents ||
      this.props.showFormCaliforniaAgents
    ) {
      document.head.removeChild(this.scriptElement);
    }
  }

  /*
    handleButtonClick: functionality when see carriers button is clicked
  */
  handleButtonClick = () => {
    const { slug } = this.state;
    const { states } = this.props;
    const url = slug
      .toLowerCase()
      .split(' ')
      .join('-');
    this.setState(() => ({
      suggestions: [],
    }));
    for (let i = 0; i < states.edges.length; i += 1) {
      if (url === states.edges[i].node.stateSlug) {
        navigate(`/carriers/${url}`);
      }
      if (url.length === 0) {
        navigate('/carriers');
      }
    }
  };

  /*
    handleInputChange: Executes on input box when changed
  */
  handleInputChange = () => {
    const { states } = this.props;
    const inputValue = this.inputRef.current.value;
    let suggestions = [];
    if (inputValue.length > 0) {
      const regex = new RegExp(`${inputValue}`, 'i');
      suggestions = states.edges
        .sort()
        .filter((item) => regex.test(item.node.stateTitle));
    }
    this.setState(() => ({ suggestions, text: inputValue, slug: inputValue }));
  };

  /*
    handleKeyDown: Executes on input box when changed
  */
  handleKeyDown = (e) => {
    const keycode = e.keyCode;
    if (keycode === 13) {
      this.handleButtonClick();
    }
  };

  /*
    suggestionSelected: Selects suggestions and populates data to input box
  */
  suggestionSelected(value) {
    this.setState(() => ({
      text: value,
      slug: value,
      suggestions: [],
    }));
  }

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.slice(0, 5).map((item) => (
          <li
            key={item.node.id}
            onClick={() => this.suggestionSelected(item.node.stateTitle)}
            onKeyDown={null}
          >
            {item.node.stateTitle}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      data,
      pageData,
      showStateAgentsButtons,
      style,
      innerRef,
      showCarrierFilter,
      showForm,
      showCarrierFilterCarousel,
      showInputArea,
      showFormGeorgiaAgents,
      showFormWashingtonAgents,
      showFormPennsylvaniaAgents,
      showFormArizonaAgents,
      showFormWisconsinAgents,
      showFormIndianaAgents,
      showFormUtahAgents,
      showFormOregonAgents,
      showFormCaliforniaAgents,
    } = this.props;
    const { text } = this.state;
    return (
      <div
        className={`hero-container ${
          showStateAgentsButtons ||
          showForm ||
          showFormGeorgiaAgents ||
          showFormWashingtonAgents ||
          showFormPennsylvaniaAgents ||
          showFormArizonaAgents ||
          showFormWisconsinAgents ||
          showFormIndianaAgents ||
          showFormUtahAgents ||
          showFormOregonAgents ||
          showFormCaliforniaAgents
            ? 'state'
            : ''
        }`}
      >
        <section
          className="hero-input"
          style={
            data.heroDesktopImage
              ? {
                  backgroundImage: `url(${getImage(data.heroDesktopImage)})`,
                }
              : {
                  backgroundColor: '#170f4f',
                }
          }
        >
          {data.heroDesktopImage || data.heroMobileImage ? (
            <figure className="hero-image">
              <GatsbyImage
                className="desktop-image"
                image={getImage(data.heroDesktopImage)}
                alt={data.title}
              />
              {data.heroMobileImage ? (
                <GatsbyImage
                  className="mobile-image"
                  image={getImage(data.heroMobileImage)}
                  alt={data.title}
                />
              ) : (
                <GatsbyImage
                  className="mobile-image"
                  image={getImage(data.heroDesktopImage)}
                  alt={data.title}
                />
              )}
            </figure>
          ) : null}
          <div
            className={
              data.heroDesktopImage || data.heroMobileImage
                ? 'overlay hero-with-image'
                : 'overlay'
            }
          >
            {data.heroDesktopImage || data.heroMobileImage ? (
              <div className="block-container">
                <span className="block-one" />
                <span className="block-two" />
                <span className="block-three" />
                <span className="block-four" />
              </div>
            ) : null}
            <div className="wrapper">
              <div className="hero-input-content" style={style} ref={innerRef}>
                {data.title ? (
                  <h1 className="hero-input-title">{parse(data.title)}</h1>
                ) : null}
                {data.description ? <h2>{parse(data.description)}</h2> : null}
                {showInputArea ? (
                  <div className="hero-input-area">
                    <input
                      value={text}
                      type="text"
                      placeholder="Enter your state here"
                      ref={this.inputRef}
                      onChange={this.handleInputChange}
                      onKeyDown={this.handleKeyDown}
                    />
                    {this.renderSuggestions()}
                    <button type="button" onClick={this.handleButtonClick}>
                      <span>See carriers</span>
                      <RightArrowIcon />
                    </button>
                  </div>
                ) : null}
                {showStateAgentsButtons ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                    <ContactContext.Consumer>
                      {({ toggleModal }) => (
                        <div
                          className="action-panel-link"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleModal();
                          }}
                        >
                          <PrimaryButton
                            text="Talk to one of our Experts"
                            color="primary-yellow state"
                            icon
                          />
                        </div>
                      )}
                    </ContactContext.Consumer>
                  </div>
                ) : null}
                {showForm ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormGeorgiaAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormWashingtonAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormPennsylvaniaAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormArizonaAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormWisconsinAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormIndianaAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormUtahAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormOregonAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
                {showFormCaliforniaAgents ? (
                  <div
                    className="hero-buttons-area"
                    style={style}
                    ref={innerRef}
                  >
                    <PrimaryButton
                      text="Learn More"
                      link="/our-plans"
                      color="primary-navy state"
                      icon
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        {showForm ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="ed8ba082-306d-4c34-b77f-eed9f9fec2fb"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormGeorgiaAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="8a6be36e-32d5-4467-98b0-f437547c75ee"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormWashingtonAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="880ed834-014c-4788-94b9-e2ba1feb346a"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormPennsylvaniaAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="d28b9f3e-ec00-453a-9b6e-74b6d02b8eed"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormArizonaAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="ffecafec-68a7-44a7-b7d6-a0b2b3fcc920"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormWisconsinAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="df26aec2-5562-4488-ab85-52a6773d97dd"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormIndianaAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="40cdd569-6d26-4914-aa13-9802f9cd3186"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormUtahAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="e8ccc501-b769-4401-b252-231460699933"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormOregonAgents ? (
          <div className="form-a">
            <div className="form-header">
              <h5>Talk to one of our Experts</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="cecac77c-9355-4ae8-b0c7-dfa3bc0e2f5d"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showFormCaliforniaAgents ? (
          <div className="form-a-new">
            <div className="form-header">
              <h5>Get in Touch</h5>
            </div>
            <div className="form-body">
              <Form
                portalId="7013510"
                formId="4c5d3599-518f-45e8-8b3e-991a8faaae21"
                targetId="carriert-bg-form"
              />
            </div>
          </div>
        ) : null}
        {showCarrierFilterCarousel ? (
          <CarrierListCarousel pageData={pageData} />
        ) : null}
        {showCarrierFilter ? <CarrierList pageData={pageData} /> : null}
      </div>
    );
  }
}

HeroInput.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  states: PropTypes.objectOf(PropTypes.array),
  pageData: PropTypes.objectOf(PropTypes.object),
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
  showInputArea: PropTypes.bool,
  showStateAgentsButtons: PropTypes.bool,
  showForm: PropTypes.bool,
  showCarrierFilterCarousel: PropTypes.bool,
  showCarrierFilter: PropTypes.bool,
  showFormGeorgiaAgents: false,
  showFormWashingtonAgents: false,
  showFormPennsylvaniaAgents: false,
  showFormArizonaAgents: false,
  showFormWisconsinAgents: false,
  showFormIndianaAgents: false,
  showFormUtahAgents: false,
  showFormOregonAgents: false,
  showFormCaliforniaAgents: false,
};

HeroInput.defaultProps = {
  states: PropTypes.undefined,
  pageData: PropTypes.undefined,
  showInputArea: false,
  showStateAgentsButtons: false,
  showForm: false,
  showCarrierFilterCarousel: false,
  showCarrierFilter: false,
  showFormGeorgiaAgents: false,
  showFormWashingtonAgents: false,
  showFormPennsylvaniaAgents: false,
  showFormArizonaAgents: false,
  showFormWisconsinAgents: false,
  showFormIndianaAgents: false,
  showFormUtahAgents: false,
  showFormOregonAgents: false,
  showFormCaliforniaAgents: false,
};

export default AnimateIn(HeroInput);
