import React from 'react';
import PropTypes from 'prop-types';
import { get, filter } from 'lodash';
import parse from 'html-react-parser';
import { StaticQuery, graphql } from 'gatsby';

import { SecondaryButton } from '../elements/buttons';
import ListContainer from '../list-container';

import './styles.scss';

// Query
const ArticleQuery = graphql`
  query listQuery {
    allDatoCmsOnDemandTraining(
      limit: 6
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsWebinar(
      limit: 5
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsCarrierBrochure(
      limit: 3
      sort: { fields: meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        title
        uploadFile {
          url
          size
          format
        }
        views
        originalId
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
      }
    }
    allDatoCmsAgencyGuide(
      limit: 3
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsAgencySuccessStory(
      limit: 3
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: slug)
      nodes {
        id
        previewTitle
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
              articleTagTitle
              slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsArticle(
      limit: 3
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsBlog(
      limit: 3
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsPressRelease(
      limit: 3
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
  }
`;

const ListArticles = ({ data, isVertical, totalCount }) => (
  <StaticQuery
    query={ArticleQuery}
    render={(queryData) => {
      const keyTagMap = {
        select_on_demand_training_article: 'on-demand-training',
        select_agency_guide_article: 'agency-guide',
        select_agency_success_story: 'agency-success-stories',
        select_carrier_brouchers_article: 'carrier-brochures',
        select_webinar_article: 'webinar',
        select_article: 'article',
        select_blog_article: 'blog',
        select_press_release_article: 'press-releases',
      };

      const componentProps = {
        title: get(data, 'title'),
        ctaText: get(data, 'seeAllCtaLabel'),
        ctaLink:
          get(data, 'selectArticle[0].selectArticleTagCategory.slug') ||
          keyTagMap[get(data, 'model.apiKey')],
        articlesLoaded: get(data, 'selectArticle'),
        backgroundColor: get(data, 'backgroundColor'),
        totalCount,
      };

      // Default List
      const getDefaultList = (articleData, slug) => {
        const articleKeys = Object.keys(articleData);
        const [selectedKeyname] = filter(articleKeys, (eachItem) => {
          const selectedSlug = slug === get(queryData[eachItem], 'distinct[0]');
          return selectedSlug;
        });

        return get(articleData[selectedKeyname], 'nodes');
      };

      const articleList =
        Array.isArray(componentProps.articlesLoaded) &&
        componentProps.articlesLoaded.length
          ? componentProps.articlesLoaded
          : getDefaultList(queryData, componentProps.ctaLink);

      return (
        <section
          className={`article-list ${
            isVertical === true ? 'carrier-brochures' : ''
          }`}
          id={componentProps.ctaLink}
          style={
            componentProps.backgroundColor
              ? { backgroundColor: componentProps.backgroundColor.hex }
              : { backgroundColor: 'transparent' }
          }
        >
          {Array.isArray(articleList) && articleList.length ? (
            <div className="wrapper">
              {componentProps.title ||
              totalCount > 0 ||
              componentProps.ctaText ? (
                <div className="heading">
                  {componentProps.title || totalCount > 0 ? (
                    <div className="heading-content">
                      {componentProps.title
                        ? parse(componentProps.title)
                        : null}
                      {totalCount > 0 && (
                        <span className="count">{totalCount}</span>
                      )}
                    </div>
                  ) : null}
                  {componentProps.ctaText ? (
                    <SecondaryButton
                      text={componentProps.ctaText}
                      link={`/${componentProps.ctaLink}`}
                    />
                  ) : null}
                </div>
              ) : null}
              {Array.isArray(articleList) && articleList.length ? (
                <div
                  className="article-filter"
                  style={
                    isVertical === true ? { justifyContent: 'flex-end' } : null
                  }
                >
                  <ListContainer list={articleList} />
                </div>
              ) : null}
            </div>
          ) : null}
        </section>
      );
    }}
  />
);

// Prop types
ListArticles.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  isVertical: PropTypes.bool,
  totalCount: PropTypes.number,
};

// Default props
ListArticles.defaultProps = {
  totalCount: null,
  isVertical: false
};

export default ListArticles;
