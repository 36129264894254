import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import './styles.scss';

const Stats = ({ data, style, innerRef }) => (
  <section className="stats">
    <div className="wrapper">
      <div className="stats-content" style={style} ref={innerRef}>
        {data.statsItem.statsName ? <h3>{data.statsItem.statsName}</h3> : null}
        {data.statsItem.statDescription ? (
          <p className="stats-description">{data.statsItem.statDescription}</p>
        ) : null}
        <div className="stats-flex-container">
          {Array.isArray(data.statsItem.statsItems)
            ? data.statsItem.statsItems.map((item) => (
                <div
                  className="stats-flexbox"
                  key={item.id}
                  style={
                    (style,
                    data.statsItem.statsItems.length < 3
                      ? {
                          width: `${100 / data.statsItem.statsItems.length}%`,
                        }
                      : null)
                  }
                  ref={innerRef}
                >
                  <div className="stats-flex-content">
                    {item.statsInfo ? (
                      <p
                        className="stats-overview"
                        style={{ color: item.statsColor.hex }}
                      >
                        {item.statsInfo}
                      </p>
                    ) : null}
                    {item.statsDescription ? (
                      <p>{parse(item.statsDescription)}</p>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  </section>
);

Stats.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(Stats);
