import FetchUtils from './fetch-utils';

const PROTO = {
  /**
   * Merge articles from all the available tag models.
   *
   * @param {*} [data={}] Node of all the tags
   * @returns List of array
   */
  mergeArticleList: (data = {}) => {
    const tagModels = Object.keys(data) || [];
    let articles = [];
    for (let i = 0; i < tagModels.length; i += 1) {
      const eachModel = tagModels[i];
      articles = [...articles, ...data[eachModel].nodes];
    }
    return articles;
  },

  // Sort blogs based on publisedAt
  sortRecentDate: (list = []) => {
    const sortedList = list.sort(
      (a, b) => new Date(b.meta.publishedAt) - new Date(a.meta.publishedAt)
    );
    return sortedList;
  },

  // Sort blogs based on views
  sortPopularity: (list = []) => {
    const sortedList = list.sort((a, b) => Number(b.views) - Number(a.views));
    return sortedList;
  },

  /**
   * Increment the views of blogs everytime a user visits the details page
   *
   * @param {*} currentViews The number of views the blog has currently
   * @param {*} id Id of the blog post
   * @param {*} token DatoCMS token
   */
  increaseViews: async (currentViews, id, token) => {
    const incrementedViews = Number(currentViews) + 1;

    // Request header
    const config = {
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    // Request payload
    const body = {
      data: {
        id,
        type: 'item',
        attributes: {
          views: incrementedViews,
        },
      },
    };

    // Update the view field in blog record
    await FetchUtils.putData(
      `https://site-api.datocms.com/items/${id}`,
      body,
      config
    );
  },
};

const listingUtils = Object.create(PROTO);

export default listingUtils;
