import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { get, map, filter } from 'lodash';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

// Utils
import ListingUtils from '../../utils/listing-utils';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';
import './styles.scss';

// Query
const TOKEN_QUERY = graphql`
  query siteToken {
    site {
      siteMetadata {
        datoToken
      }
    }
    allDatoCmsAgencyGuide(
      limit: 1
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsAgencySuccessStory(
      limit: 1
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        previewTitle
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsArticle(
      limit: 1
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsBlog(
      limit: 1
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
    allDatoCmsPressRelease(
      limit: 1
      sort: { fields: selectStateTag___meta___publishedAt, order: DESC }
    ) {
      totalCount
      distinct(field: selectArticleTagCategory___slug)
      nodes {
        id
        title
        subtitle
        slug
        views
        originalId
        featuredImage {
          url
        }
        selectArticleTagCategory {
          articleTagTitle
          slug
        }
        meta {
          publishedAt(formatString: "DD MMMM, YYYY")
          updatedAt
        }
      }
    }
  }
`;

// Article List
const getArticleList = (query) => {
  const articleKeys = Object.keys(query);
  const list = map(articleKeys, (eachItem) => {
    if (query[eachItem]) {
      return get(query[eachItem], 'nodes[0]');
    }
  });
  const filteredList = filter(list, (eachItem) => !!eachItem);
  return filteredList;
};

const getLatestArticle = (list) => {
  let latestArticle = [];
  if (Array.isArray(list)) {
    latestArticle = ListingUtils.sortRecentDate(list);
  }
  return latestArticle[0];
};

const ArticlePromo = ({ data, style, innerRef }) => (
  <StaticQuery
    query={TOKEN_QUERY}
    render={(queryData) => {
      const articleList = getArticleList(
        queryData,
        get(data, 'featuredArticle.selectArticleTagCategory.slug')
      );

      // Default latest article
      const defaultArticle = getLatestArticle(articleList);

      // Component Props
      const ComponentProps = {
        tagSlug:
          get(data, 'featuredArticle.selectArticleTagCategory.slug') ||
          get(defaultArticle, 'selectArticleTagCategory.slug'),
        articleSlug:
          get(data, 'featuredArticle.slug') || get(defaultArticle, 'slug'),
        tagTitle:
          get(
            data,
            'featuredArticle.selectArticleTagCategory.articleTagTitle'
          ) || get(defaultArticle, 'selectArticleTagCategory.articleTagTitle'),
        createdAt:
          get(data, 'featuredArticle.meta.publishedAt') ||
          get(defaultArticle, 'meta.publishedAt'),
        title:
          get(data, 'featuredArticle.title') || get(defaultArticle, 'title'),
        subtitle:
          get(data, 'featuredArticle.subtitle') ||
          get(defaultArticle, 'subtitle'),
        featuredImage:
          get(data, 'featuredArticle.featuredImage.url') ||
          get(defaultArticle, 'featuredImage.url'),
        originalId:
          get(data, 'featuredArticle.originalId') ||
          get(defaultArticle, 'originalId'),
        views:
          get(data, 'featuredArticle.views') || get(defaultArticle, 'views'),
      };

      if (!get(ComponentProps, 'tagSlug')) return null;

      return (
        <section className="article-promo" id="featured">
          <div className="wrapper">
            <div className="article-promo-content" style={style} ref={innerRef}>
              <div className="article-promo-flex-container">
                <div className="article-promo-flexbox">
                  <div className="article-promo-flex-content">
                    <p className="subtitle-2">
                      {ComponentProps.tagTitle ? (
                        <span className="tag">{ComponentProps.tagTitle}</span>
                      ) : null}
                      {ComponentProps.createdAt ? (
                        <span>{ComponentProps.createdAt}</span>
                      ) : null}
                    </p>
                    {ComponentProps.title ? (
                      <h1>{ComponentProps.title}</h1>
                    ) : null}
                    {ComponentProps.subtitle ? (
                      <p>{parse(ComponentProps.subtitle)}</p>
                    ) : null}
                    {ComponentProps.articleSlug ? (
                      <Link
                        to={`/${ComponentProps.tagSlug}/${ComponentProps.articleSlug}`}
                      >
                        <span>Read more</span>
                        <RightArrowIcon />
                      </Link>
                    ) : null}
                  </div>
                </div>
                <div className="article-promo-flexbox">
                  <div className="article-promo-flex-content">
                    {ComponentProps.featuredImage ? (
                      <img
                        src={ComponentProps.featuredImage}
                        alt="Featured"
                        className="object-fit-image"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }}
  />
);

ArticlePromo.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default AnimateIn(ArticlePromo);
