/* eslint-disable no-plusplus */
// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { filter, find, get } from 'lodash';
import AnimateIn from '../animate-in';

// Styles
import './styles.scss';

const animateBlocks = () => {
  // Blocks on hero component
  const fourthBlock = document.querySelector('.hero-box-container .block-four');
  if (
    document.body.scrollTop >= 1400 ||
    document.documentElement.scrollTop >= 1400
  ) {
    if (fourthBlock) {
      fourthBlock.style.transform = `translateX(${window.pageYOffset -
        4400}px)`;
    }
  }
};

// Filter line category on basis of category id
const getFilteredLineCategory = (lineCategoryList = [], categoryId) => {
  let carrierCount = 0;
  const filteredList = filter(lineCategoryList, (eachLineCategory) => {
    // Parent category i.e Personal / Commercial line
    const filterCategory = get(
      eachLineCategory,
      'lineFilter.selectCarrierCategory'
    );

    const isMatchingCategory = find(filterCategory, ['originalId', categoryId]);

    // Count selected carriers
    if (isMatchingCategory) {
      // Carrier attached
      const carrierSelected = get(eachLineCategory, 'carriers');
      carrierCount += carrierSelected.length;
    }
    // Filter if carrier is not present or is not match to the parent category
    return isMatchingCategory && get(eachLineCategory, 'carriers').length;
  });

  return {
    filteredLineCategory: filteredList || [],
    carrierCount,
  };
};

// Get a list of all the unique categories selected in the line category lis
const getUniqueCategoryFilters = (list = []) => {
  let uniqueCategoryFilters = [];
  // Loop through all attached filters for a state
  for (let i = 0; i < list.length; i++) {
    const currentLineCategory = list[i];
    //  Parent category
    const filterCategory = get(
      currentLineCategory,
      'lineFilter.selectCarrierCategory'
    );

    // Loop through all category filter that are attached to a line filter
    for (let j = 0; j < filterCategory.length; j++) {
      const currentCategory = filterCategory[j];
      // Check if the category has already been added in the unique category filter array
      const checkIfCategoryExists = find(uniqueCategoryFilters, [
        'originalId',
        currentCategory.originalId,
      ]);
      if (!checkIfCategoryExists) {
        // Add only if category is not present in unique array
        uniqueCategoryFilters = [...uniqueCategoryFilters, currentCategory];
      }
    }
  }
  return uniqueCategoryFilters;
};

// Get carrier count from filter data
const getCarrierCount = (list = []) => {
  const categoryFilters = getUniqueCategoryFilters(list);
  let stateCarrierCount = 0;
  for (let i = 0; i < categoryFilters.length; i++) {
    const { carrierCount } = getFilteredLineCategory(
      list,
      get(categoryFilters[i], 'originalId')
    );
    stateCarrierCount = +carrierCount;
  }

  return stateCarrierCount;
};

const changeDescription = (str, carrierCount) => {
  const newStr = str.replace('**', carrierCount);
  return parse(newStr);
};

const FeaturesWithImage2 = (props) => {
  let { description = '' } = props.data;
  const {
    title = '',
    contentColor = '#ffffff',
    featureDesktopImage: { url: backgroundImage },
    style,
    innerRef,
  } = props.data;
  // eslint-disable-next-line react/prop-types
  const list = props.list;

  // Handle scroll
  useEffect(() => {
    window.addEventListener('scroll', animateBlocks);

    return () => {
      window.removeEventListener('scroll', animateBlocks);
    };
  }, []);

  const carrierCount = getCarrierCount(list);
  description = changeDescription(description, carrierCount);
  // Return
  return (
    <div className="fetures-with-image2-container">
      <section
        className={
          backgroundImage
            ? 'fetures-with-image2 image-content'
            : 'fetures-with-image2'
        }
      >
        <div
          className={backgroundImage ? 'overlay hero-with-image' : 'overlay'}
        >
          {backgroundImage ? (
            <div className="hero-box-container">
              <span className="block-four" />
            </div>
          ) : null}
          <div className="wrapper">
            <div
              className={
                !description ? 'hero-content single-title' : 'hero-content'
              }
              style={style}
              ref={innerRef}
            >
              {title ? (
                <h1
                  style={contentColor ? { color: contentColor.hex } : null}
                  className={description ? 'hero-title' : 'hero-title'}
                >
                  {parse(title)}
                </h1>
              ) : null}
              {description ? (
                <p
                  style={contentColor ? { color: contentColor.hex } : null}
                  className="description"
                >
                  {description}
                </p>
              ) : null}
            </div>
          </div>
          {backgroundImage ? (
            <img
              src={backgroundImage}
              alt="Features Background"
              className="bg-image"
              style={style}
              ref={innerRef}
            />
          ) : null}
        </div>
      </section>
    </div>
  );
};

// Proptypes
FeaturesWithImage2.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(FeaturesWithImage2);
