import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import parse from 'html-react-parser';
import { get } from 'lodash';
import AnimateIn from '../animate-in';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';
import ContactContext from '../../context/ContactContext';

import './styles.scss';

const SignPostSales = ({ data, style, innerRef }) => (
  <ContactContext.Consumer>
    {({ toggleModal }) => (
      <section
        className="signpost-sales"
        style={
          data.backgroundColor
            ? { backgroundColor: data.backgroundColor.hex }
            : null
        }
      >
        <div className="signpost-sales-content-yellow">
          <div className="wrapper">
            <div
              className="signpost-sales-flex-container"
              style={style}
              ref={innerRef}
            >
              <div className="signpost-sales-flexbox">
                <div className="signpost-sales-flex-content">
                  {get(data, 'signPostSalesList.salesTitle') ? (
                    <h4>{get(data, 'signPostSalesList.salesTitle')}</h4>
                  ) : null}
                  {get(data, 'signPostSalesList.salesDescription') ? (
                    <div>
                      {parse(get(data, 'signPostSalesList.salesDescription'))}
                    </div>
                  ) : null}
                  {get(data, 'signPostSalesList.readyToTalkCtaText') ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (get(data, 'signPostSalesList.readyToTalkCtaLink')) {
                          navigate(
                            get(data, 'signPostSalesList.readyToTalkCtaLink')
                          );
                        } else {
                          toggleModal();
                        }
                      }}
                    >
                      <span>
                        {get(data, 'signPostSalesList.readyToTalkCtaText')}
                      </span>
                      <RightArrowIcon />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signpost-sales-content-navy">
          <div className="wrapper">
            <div className="signpost-sales-flex-container">
              <div
                className="signpost-sales-flexbox"
                style={style}
                ref={innerRef}
              >
                <div className="signpost-sales-flex-content">
                  {get(data, 'signPostSalesList.getInTouchTitle') ? (
                    <h4>{get(data, 'signPostSalesList.getInTouchTitle')}</h4>
                  ) : null}
                  {get(data, 'signPostSalesList.getInTouchDescription') ? (
                    <div>
                      {parse(
                        get(data, 'signPostSalesList.getInTouchDescription')
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                className="signpost-sales-flexbox"
                style={style}
                ref={innerRef}
              >
                <div className="signpost-sales-flex-content">
                  {get(data, 'signPostSalesList.callUsTitle') ? (
                    <h4>{get(data, 'signPostSalesList.callUsTitle')}</h4>
                  ) : null}
                  {get(data, 'signPostSalesList.callUsNumber') ? (
                    <h6>
                      <a
                        href={`tel: ${get(
                          data,
                          'signPostSalesList.callUsNumber'
                        )}`}
                        className="phone"
                      >
                        {get(data, 'signPostSalesList.callUsNumber')}
                      </a>
                    </h6>
                  ) : null}
                  {get(data, 'signPostSalesList.sendUsMessage') ? (
                    <h4>
                      {parse(get(data, 'signPostSalesList.sendUsMessage'))}
                    </h4>
                  ) : null}
                  <Link
                    to="https://meetings.hubspot.com/szace1/bolt-access-meeting"
                    target="_blank"
                  >
                    <span>Book now</span>
                  </Link>
                </div>
              </div>
              <div
                className="signpost-sales-flexbox"
                style={style}
                ref={innerRef}
              >
                <div className="signpost-sales-flex-content">
                  {get(data, 'signPostSalesList.aboutUsTitle') ? (
                    <h4>{get(data, 'signPostSalesList.aboutUsTitle')}</h4>
                  ) : null}
                  {get(data, 'signPostSalesList.signUpDescription') ? (
                    <h6>
                      {parse(get(data, 'signPostSalesList.signUpDescription'))}
                    </h6>
                  ) : null}
                  {get(data, 'signPostSalesList.aboutUsCtaText') ? (
                    <Link to={get(data, 'signPostSalesList.aboutUsLink')}>
                      <span>
                        {get(data, 'signPostSalesList.aboutUsCtaText')}
                      </span>
                      <RightArrowIcon />
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  </ContactContext.Consumer>
);

SignPostSales.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(SignPostSales);
