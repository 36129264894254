/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

import ContactContext from '../../context/ContactContext';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';
import EnvelopIcon from '../../assets/svgs/icon-envelop.svg';
import './styles.scss';

// Query
export const ACTION_PANEL_QUERY = graphql`
  query TalkToUsQuery {
    allDatoCmsTalkToBoltAccess {
      nodes {
        talkToBoltAccessLink
        talkToBoltAccessTitle
        talkToBoltAccessIcon {
          size
          width
          height
          path
          format
          isImage
          notes
          author
          copyright
          filename
          basename
          exifInfo
          mimeType
          blurhash
          originalId
          url
          createdAt
          alt
          title
          customData
        }
      }
    }
  }
`;

const ActionPanel = () => {
  const talkAgent = useRef(null);

  const animateActionPanel = () => {
    const component = talkAgent.current;
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      component.classList.add('scrolled');
    } else {
      component.classList.remove('scrolled');
    }
  };

  // Handle scroll
  useEffect(() => {
    window.addEventListener('scroll', animateActionPanel);

    return () => {
      window.removeEventListener('scroll', animateActionPanel);
    };
  }, []);

  return (
    <StaticQuery
      query={ACTION_PANEL_QUERY}
      render={(data) => {
        const componentData = get(data, 'allDatoCmsTalkToBoltAccess.nodes[0]');
        const ComponentProps = {
          title: get(componentData, 'talkToBoltAccessTitle'),
          link: get(componentData, 'talkToBoltAccessLink'),
          icon: get(componentData, 'talkToBoltAccessIcon'),
        };

        // render
        return (
          <ContactContext.Consumer>
            {({ toggleModal }) => (
              <section>
                <div
                  className="action-panel-link"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal();
                  }}
                >
                  <div className="action-panel" ref={talkAgent}>
                    <div className="action-panel-content">
                      <EnvelopIcon />
                      <div className="action-panel-text">
                        <h4>
                          {ComponentProps.title ||
                            'Talk to a Bolt Access Agent'}
                        </h4>
                        <p className="body-2">
                          Contact us
                          <RightArrowIcon />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </ContactContext.Consumer>
        );
      }}
    />
  );
};

export default ActionPanel;
