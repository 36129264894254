/* eslint-disable no-console */
import axios from 'axios';

const PROTO = {
  /**
   * Post data
   *
   */
  postData: async (url, body, config) => {
    try {
      const response = await axios.post(url, body, config);
      return response;
    } catch (error) {
      // console.error('error ^^^^^^^', error);
    }
  },

  /**
   * Put data
   *
   */
  putData: async (url, body, config) => {
    try {
      const response = await axios.put(url, body, config);
      return response;
    } catch (error) {
      // console.error('error ^^^^^^^', error);
    }
  },
};

const FetchUtils = Object.create(PROTO);

export default FetchUtils;
