import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import './styles.scss';

const RichText = ({ data, style, innerRef }) => (
  <div className="rich-text" style={style} ref={innerRef}>
    <div className="wrapper">{data ? parse(data.addContent) : null}</div>
  </div>
);

RichText.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(RichText);
