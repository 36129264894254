/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */

// Libraries
import React from 'react';

// Components
import HeroInput from '../components/hero-input';
import ActionPanel from '../components/action-panel';
import CarrierList from '../components/carrier-list';
import Intro from '../components/intro';
import Stats from '../components/stats';
import StatesPromo from '../components/states-promo';
import ArticlePromo from '../components/article-promo';
import SignPostCentered from '../components/sign-post-centered';
import SignPostSales from '../components/sign-post-sales';
import SignPost from '../components/sign-post';
import Partners from '../components/partners';
import IntroCta from '../components/intro-cta';
import LinkPromo from '../components/link-promo';
import FeaturedArticles from '../components/featured-articles';
import SignPostImage from '../components/sign-post-image';
import FeatureCta from '../components/feature-cta';
import FeatureCta2 from '../components/feature-cta2';
import FeatureCta3 from '../components/feature-cta3';
import PlansPromo from '../components/plans-promo';
import FeaturesBolt from '../components/features-bolt';
import FeaturesWithImage2 from '../components/features-with-image2';
import Accordion from '../components/accordion';
import RelatedArticle from '../components/related-article';
import ListArticles from '../components/list-articles';
import RichText from '../components/rich-text';
import CarrierPromo from '../components/carrier-promo';
import ContactPromo from '../components/contact-promo';
import IframeWrapper from '../components/ifame-wrapper';
import InsuranceTabs from '../components/insurance-tabs';
import Leadership from '../components/leadership';

const componentMap = (label, props, key) => {
  const map = {
    hero: <HeroInput key={key} {...props} />,
    talk_us: <ActionPanel key={key} />,
    carrier_list: <CarrierList key={key} />,
    intro: <Intro key={key} {...props} />,
    stats_content: <Stats key={key} {...props} />,
    state_promo: <StatesPromo key={key} {...props} />,
    featured_article: <ArticlePromo key={key} {...props} />,
    newsroom_page_featured_article: <ArticlePromo key={key} {...props} />,
    resource_page_featured_article: <ArticlePromo key={key} {...props} />,
    sign_post_centered_list: <SignPostCentered key={key} {...props} />,
    sign_post_sales_list: <SignPostSales key={key} {...props} />,
    sign_post_default_list: <SignPost key={key} {...props} />,
    partner: <Partners key={key} {...props} />,
    intro_with_cta: <IntroCta key={key} {...props} />,
    link_promo: <LinkPromo key={key} {...props} />,
    webinar_article_list: <FeaturedArticles key={key} {...props} />,
    agency_guide_article_list: <FeaturedArticles key={key} {...props} />,
    agency_success_story_list: <FeaturedArticles key={key} {...props} />,
    article_list: <FeaturedArticles key={key} {...props} />,
    blog_article_list: <FeaturedArticles key={key} {...props} />,
    on_demand_article_list: <FeaturedArticles key={key} {...props} />,
    press_release_article_list: <FeaturedArticles key={key} {...props} />,
    sign_post_image_list: <SignPostImage key={key} {...props} />,
    features_with_cta: <FeatureCta key={key} {...props} />,
    features_with_cta2: <FeatureCta2 key={key} {...props} />,
    feature_with_cta_partner: <FeatureCta3 key={key} {...props} />,
    features_with_cta_quote: <FeatureCta3 key={key} {...props} />,
    plan_card: <PlansPromo key={key} {...props} />,
    features_with_image: <FeaturesBolt key={key} {...props} />,
    features_with_image2: <FeaturesWithImage2 key={key} {...props} />,
    faq_accordion: <Accordion key={key} {...props} />,
    related_article_section: <RelatedArticle key={key} {...props} />,
    related_blog_article: <RelatedArticle key={key} {...props} />,
    related_webinar_article_section: <RelatedArticle key={key} {...props} />,
    related_press_release_article: <RelatedArticle key={key} {...props} />,
    related_on_demand_training_section: <RelatedArticle key={key} {...props} />,
    related_agency_guide_article: <RelatedArticle key={key} {...props} />,
    related_agency_success_story: <RelatedArticle key={key} {...props} />,
    select_on_demand_training_article: <ListArticles key={key} {...props} />,
    select_carrier_brouchers_article: <ListArticles key={key} {...props} />,
    select_webinar_article: <FeaturedArticles key={key} {...props} />,
    select_agency_guide_article: <ListArticles key={key} {...props} />,
    select_agency_success_story: <ListArticles key={key} {...props} />,
    select_article: <ListArticles key={key} {...props} />,
    select_blog_article: <ListArticles key={key} {...props} />,
    select_press_release_article: <ListArticles key={key} {...props} />,
    rich_text: <RichText key={key} {...props} />,
    carrier_promo: <CarrierPromo key={key} {...props} />,
    contact: <ContactPromo key={key} {...props} />,
    iframe: <IframeWrapper key={key} {...props} />,
    add_insurance_tab: <InsuranceTabs key={key} {...props} />,
    leadership: <Leadership key={key} {...props} />,
  };

  return map[label];
};

export default componentMap;
