import React from 'react';
import Slider from 'react-slick';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

const LeadershipModal = ({ data, val, onClose }) => {
  const value = val;
  const modalSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: Number(value),
    dots: false,
    arrows: true,
  };

  return (
    <div className="leadership-modal" id="modal-container">
      <div className="modal-content">
        <ul className="modal-list leadership-card-container">
          <Slider {...modalSettings}>
            {data.leadershipList.map((item) => (
              <li className="leadership-list-item">
                <div className="leadership-content-container">
                  <div
                    className="leadership-image"
                    onClick={onClose}
                    onKeyPress={null}
                  >
                    <figure>
                      <img src={item.profileImage.url} alt="leader" />
                    </figure>
                  </div>
                  <div className="leadership-detail">
                    <span
                      className="close"
                      id="close"
                      onClick={onClose}
                      role="button"
                      tabIndex={0}
                      onKeyPress={null}
                    >
                      Close
                    </span>
                    {item.profileName ? (
                      <div className="profile-name">
                        <h2>{item.profileName}</h2>
                      </div>
                    ) : null}
                    {item.profileRoles ? (
                      <div className="profile-role">
                        {parse(item.profileRoles)}
                      </div>
                    ) : null}
                    {item.roleDescription ? (
                      <div className="profile-description">
                        {parse(item.roleDescription)}
                      </div>
                    ) : null}
                    {item.socialLink ? (
                      <div className="profile-socials">
                        <ul>
                          <span>→ </span>
                          {Array.isArray(item.socialLink)
                            ? item.socialLink.map((link) => (
                                <li key={link.key}>
                                  <a
                                    href={link.socialLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {link.socialLinkText}
                                  </a>
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </li>
            ))}
          </Slider>
        </ul>
      </div>
    </div>
  );
};

LeadershipModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  val: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LeadershipModal;
