import React, { useState } from 'react';
import Slider from 'react-slick';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import Plandetailmodal from '../modal/plan-modal/index';
import AnimateIn from '../animate-in';
import VectorIcon from '../../assets/svgs/Vector.svg';
import CloseVector from '../../assets/svgs/close-vector.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

const PlansPromo = ({ data, style, innerRef }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const [count, setCount] = useState([]);
  const [displayModal, setModal] = useState(false);

  function modal(index) {
    setCount(index);
    setModal(true);
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
  }

  function closeModal() {
    setModal(false);
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
  }

  return (
    <section className="plans-promo">
      <div className="wrapper">
        {data.planHeaderTitle ? (
          <h3 className="plans-promo-heading" style={style} ref={innerRef}>
            {data.planHeaderTitle}
          </h3>
        ) : null}
        {data.planHeaderDescription ? (
          <p className="plans-promo-description" style={style} ref={innerRef}>
            {parse(data.planHeaderDescription)}
          </p>
        ) : null}
        <ul
          className="plan-card-container"
          id="plan-slide"
          style={style}
          ref={innerRef}
        >
          <Slider {...settings}>
            {data.planLink.map((item, index) => (
              <li
                className="plan-list-item"
                onClick={() => modal(index)}
                onKeyPress={null}
              >
                {item.planIcon ? (
                  <figure>
                    <img src={item.planIcon.url} alt={item.planIcon.alt} />
                  </figure>
                ) : null}
                <h4 className="plan-title">{item.planTitle}</h4>
                <div className="presonal-line">
                  <h5 className="list-heading">{item.personalLineTitle}</h5>
                  <div className="percent-list-container">
                    {item.personalRate.map((key) => (
                      <div className="percent-list">
                        <span className="percent">{key.personalRateValue}</span>
                        <span className="precent-text">
                          {key.personalRateCategory}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="commercial-line">
                  <h5 className="list-heading">{item.commercialLinesTitle}</h5>
                  <div className="percent-list-container">
                    {item.commercialLine.map((key) => (
                      <div className="percent-list">
                        <span className="percent">{key.commercialRate}</span>
                        <span className="precent-text">
                          {key.commercialRateCategory}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="feature-list">
                  <ul>
                    {item.featuresList.map((key) => (
                      <div className="feature-list-item">
                        <div
                          className={`feature-heading ${
                            key.featureAvailable ? 'available' : 'not-available'
                          }`}
                        >
                          <span>{key.featureTitle}</span>
                          {key.featureAvailable ? (
                            <VectorIcon />
                          ) : (
                            <CloseVector className="closeVector" />
                          )}
                        </div>
                        <span className="feature-content">
                          {key.featureValue}
                        </span>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className="plan-price">
                  {item.planPrice.map((key) => (
                    <div>
                      <span className="price">{key.planPrice}</span>
                      <span className="price-month">{key.planDuration}</span>
                    </div>
                  ))}
                </div>
                <div className="select-plan">
                  {item.planDetailCtaText ? (
                    <a
                      className="plan-detail-cta"
                      href={item.planDetailCtaLink}
                      title={item.planDetailCtaText}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        modal(index);
                      }}
                    >
                      {item.planDetailCtaText}
                    </a>
                  ) : null}
                  {item.planCtaText ? (
                    <a
                      href={item.planCtaLink}
                      title={item.planCtaText}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.planCtaText}
                    </a>
                  ) : null}
                </div>
              </li>
            ))}
          </Slider>
        </ul>
        <div className="modal-slick-container">
          {displayModal ? (
            <div className="content-modal">
              <div className="wrapper">
                <span
                  className="close"
                  id="close"
                  onClick={closeModal}
                  role="button"
                  tabIndex={0}
                  onKeyPress={null}
                >
                  close
                </span>
                <Plandetailmodal
                  data={data}
                  val={count}
                  modal={displayModal}
                  closeModal={() => closeModal()}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

PlansPromo.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(PlansPromo);
