import React, { useState } from 'react';
import parse from 'html-react-parser';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import LeadershipModal from '../modal/leadership-modal';
import AnimateIn from '../animate-in';

import './style.scss';

const Leadership = ({ data, style, innerRef }) => {
  const [count, setCount] = useState([]);
  const [displayModal, setModal] = useState(false);

  function modal(index) {
    setCount(index);
    setModal(true);
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
  }

  function closeModal() {
    setModal(false);
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
  }

  return (
    <section className="leadership">
      <div className="wrapper">
        {data.title ? (
          <h3 className="leadership-heading" style={style} ref={innerRef}>
            {data.title}
          </h3>
        ) : null}
        {data.subtitle ? (
          <div className="leadership-description" style={style} ref={innerRef}>
            {parse(data.subtitle)}
          </div>
        ) : null}
        <div className="leadership-flex-container" style={style} ref={innerRef}>
          {Array.isArray(data.leadershipList)
            ? data.leadershipList.map((item, index) => (
                <div key={item.id} className="leadership-flexbox">
                  <div
                    className="leadership-flex-content"
                    onClick={() => modal(index)}
                    onKeyPress={null}
                  >
                    {item.profileImage ? (
                      <figure>
                        <GatsbyImage
                          image={getImage(item.profileImage.gatsbyImageData)}
                          alt="leaders"
                        />
                      </figure>
                    ) : null}
                    {item.profileName ? (
                      <div className="profile-name">
                        <h4>{item.profileName}</h4>
                      </div>
                    ) : null}
                    {item.profileRoles ? (
                      <div className="profile-role">
                        {parse(item.profileRoles)}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="modal-slick-container">
          {displayModal ? (
            <div className="content-modal">
              <LeadershipModal
                data={data}
                val={count}
                modal={displayModal}
                onClose={() => closeModal()}
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

Leadership.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(Leadership);
