/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-body-style */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, filter, map, find } from 'lodash';
import Slider from 'react-slick';

import './styles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      onClick();
    }
  };

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      onClick();
    }
  };

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    />
  );
}

class CarrierListCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategoryIndex: 0,
      selectedLineCategoryIndex: 0,
      filterData: [],
    };
    this.settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  }

  componentDidMount() {
    const { pageData } = this.props;

    const lineCategoryList = get(pageData, 'carrierData.categoryFilter');
    const formattedFilterData = this.formatFilterList(lineCategoryList);
    this.setState({
      filterData: formattedFilterData,
    });
  }

  // Format filter list data into heihrarcy format for mapping
  formatFilterList = (list = []) => {
    const categoryFilters = this.getUniqueCategoryFilters(list);
    const formattedFilterList = map(categoryFilters, (eachCategory) => {
      const {
        filteredLineCategory: lineCategory,
        carrierCount,
      } = this.getFilteredLineCategory(list, get(eachCategory, 'originalId'));
      return { lineCategory, carrierCount, category: eachCategory };
    });
    return formattedFilterList || [];
  };

  // Filter line category on basis of category id
  getFilteredLineCategory = (lineCategoryList = [], categoryId) => {
    let carrierCount = 0;
    const filteredList = filter(lineCategoryList, (eachLineCategory) => {
      // Parent category i.e Personal / Commercial line
      const filterCategory = get(
        eachLineCategory,
        'lineFilter.selectCarrierCategory'
      );

      const isMatchingCategory = find(filterCategory, [
        'originalId',
        categoryId,
      ]);

      // Count selected carriers
      if (isMatchingCategory) {
        // Carrier attached
        const carrierSelected = get(eachLineCategory, 'carriers');
        carrierCount += carrierSelected.length;
      }
      // Filter if carrier is not present or is not match to the parent category
      return isMatchingCategory && get(eachLineCategory, 'carriers').length;
    });

    // Sort filtered data
    const sortedList = this.sortLineCategory(filteredList);

    return {
      filteredLineCategory: sortedList || [],
      carrierCount,
    };
  };

  // Sort line category based on its title
  sortLineCategory = (list = []) => {
    const sortedList = list.sort((a, b) => {
      if (
        get(a, 'lineFilter.carrierLineCategoryTitle') <
        get(b, 'lineFilter.carrierLineCategoryTitle')
      ) {
        return -1;
      }
      if (
        get(a, 'lineFilter.carrierLineCategoryTitle') >
        get(b, 'lineFilter.carrierLineCategoryTitle')
      ) {
        return 1;
      }
      return 0;
    });

    return sortedList;
  };

  // Get a list of all the unique categories selected in the line category lis
  getUniqueCategoryFilters = (list = []) => {
    let uniqueCategoryFilters = [];
    // Loop through all attached filters for a state
    for (let i = 0; i < list.length; i++) {
      const currentLineCategory = list[i];
      //  Parent category
      const filterCategory = get(
        currentLineCategory,
        'lineFilter.selectCarrierCategory'
      );

      // Loop through all category filter that are attached to a line filter
      for (let j = 0; j < filterCategory.length; j++) {
        const currentCategory = filterCategory[j];
        // Check if the category has already been added in the unique category filter array
        const checkIfCategoryExists = find(uniqueCategoryFilters, [
          'originalId',
          currentCategory.originalId,
        ]);
        if (!checkIfCategoryExists) {
          // Add only if category is not present in unique array
          uniqueCategoryFilters = [...uniqueCategoryFilters, currentCategory];
        }
      }
    }
    return uniqueCategoryFilters;
  };

  /*
    onCategoryClick: onClick category filter functionality
  */
  onCategoryClick = (index) => {
    this.setState({
      selectedCategoryIndex: index,
      selectedLineCategoryIndex: 0,
    });
  };

  /*
    onLineCategoryClick: onClick line category filter functionality
  */
  onLineCategoryClick = (index) => {
    this.slider.slickGoTo(1);
    this.setState({
      selectedLineCategoryIndex: index,
    });
  };

  render() {
    const {
      selectedCategoryIndex,
      selectedLineCategoryIndex,
      filterData,
    } = this.state;

    // Return category list is not present
    if (filterData.length <= 0) return null;

    // Selected Category Data
    const selectedCategory = filterData[selectedCategoryIndex];

    // Filtered line category based on category selected
    const filteredLineCategory = get(selectedCategory, 'lineCategory');

    // Filtered line category based on line category selected
    const filteredCarriers = get(
      filteredLineCategory[selectedLineCategoryIndex],
      'carriers'
    );
    return (
      <section className="carrier-list-carousel">
        {filterData.length > 0 ? (
          <div>
            <div className="carrier-select">
              <div className="wrapper">
                <ul>
                  {Array.isArray(filterData)
                    ? filterData.map((item, index) => {
                        const category = get(item, 'category');
                        return (
                          <li key={category.originalId}>
                            <button
                              type="button"
                              onClick={() => this.onCategoryClick(index)}
                              className={
                                index === selectedCategoryIndex ? 'active' : ''
                              }
                            >
                              {get(category, 'carrierCategoryName')}
                              {get(item, 'lineCategory').length > 0 ? (
                                <span>{get(item, 'lineCategory').length}</span>
                              ) : null}
                            </button>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
            <div className="carrier-category">
              <div className="wrapper">
                <ul>
                  {Array.isArray(filteredLineCategory)
                    ? filteredLineCategory.map((item, index) => {
                        const lineFilters = get(item, 'lineFilter');
                        // Return if name is not present
                        if (!get(lineFilters, 'carrierLineCategoryTitle')) {
                          return null;
                        }

                        return (
                          <li key={index}>
                            <button
                              type="button"
                              onClick={() => this.onLineCategoryClick(index)}
                              className={
                                index === selectedLineCategoryIndex
                                  ? 'active'
                                  : ''
                              }
                            >
                              {get(lineFilters, 'carrierLineCategoryTitle')}
                            </button>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
            <div className="carrier-active">
              <div className="wrapper">
                <p className="carrier-count subtitle-2">
                  {filteredCarriers && filteredCarriers.length > 0
                    ? `${filteredCarriers.length} carriers`
                    : 'No carriers found'}
                </p>
                <Slider
                  {...this.settings}
                  className="allElem"
                  ref={this.slider}
                >
                  {Array.isArray(filteredCarriers)
                    ? filteredCarriers.map((item, index) => (
                        <div key={index} className="elem">
                          {item.carrierLogo ? (
                            <figure>
                              <img
                                src={item.carrierLogo.url}
                                alt="carrier logo"
                              />
                            </figure>
                          ) : null}
                          {get(item, 'carrierTitle') ? (
                            <p className="carrier-name">{item.carrierTitle}</p>
                          ) : null}
                        </div>
                      ))
                    : null}
                </Slider>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

CarrierListCarousel.propTypes = {
  pageData: PropTypes.objectOf(PropTypes.object),
};

CarrierListCarousel.defaultProps = {
  pageData: PropTypes.undefined,
};

export default CarrierListCarousel;
