import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import AnimateIn from '../animate-in';
import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const settings = {
  arrows: false,
  centerPadding: '10%',
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: false,
  centerMode: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '15%',
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '25%',
      },
    },
  ],
};

const Partners = ({ data, style, innerRef, careersPath }) => {
  const componentProps = {
    title: get(data, 'title'),
    subtitle: get(data, 'subTitle'),
    ctaText: get(data, 'ctaText'),
    ctaLink: get(data, 'ctaLink'),
    logos: get(data, 'carriersLogo'),
  };

  return (
    <div>
      {careersPath ? (
        <section className="partners-careers">
          <div className="wrapper">
            <div className="partners-content" style={style}>
              <h3>{componentProps.title}</h3>
              <div>
                <p>{componentProps.subtitle}</p>
              </div>
              <Link to={componentProps.ctaLink}>
                <span>{componentProps.ctaText}</span>
                <RightArrowIcon className="partners-content-svg" />
              </Link>
            </div>
          </div>
          <div className="wrapper partner-carousel">
            <section style={style} ref={innerRef}>
              <Slider {...settings}>
                {Array.isArray(componentProps.logos)
                  ? componentProps.logos.map((item) => (
                      <div key={item.id}>
                        {item.carrierLogo ? (
                          <figure>
                            <GatsbyImage
                              image={getImage(item.carrierLogo.gatsbyImageData)}
                              alt="partner-logo"
                            />
                          </figure>
                        ) : null}
                      </div>
                    ))
                  : null}
              </Slider>
            </section>
          </div>
        </section>
      ) : null}
      {!careersPath ? (
        <section className="partners">
          <div className="wrapper">
            <div className="partners-content" style={style} ref={innerRef}>
              <h3>{componentProps.title}</h3>
              <div>
                <p>{componentProps.subtitle}</p>
              </div>
              <Link to={componentProps.ctaLink}>
                <span>{componentProps.ctaText}</span>
                <RightArrowIcon />
              </Link>
            </div>
          </div>
          <div className="wrapper partner-carousel">
            <section style={style} ref={innerRef}>
              <Slider {...settings}>
                {Array.isArray(componentProps.logos)
                  ? componentProps.logos.map((item) => (
                      <div key={item.id}>
                        {item.carrierLogo ? (
                          <figure>
                            <GatsbyImage
                              image={getImage(item.carrierLogo.gatsbyImageData)}
                              alt="partner-logo"
                            />
                          </figure>
                        ) : null}
                      </div>
                    ))
                  : null}
              </Slider>
            </section>
          </div>
        </section>
      ) : null}
    </div>
  );
};

Partners.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
  careersPath: PropTypes.bool,
};

Partners.defaultProps = {
  careersPath: false,
};

export default AnimateIn(Partners);
