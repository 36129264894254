import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';
import './styles.scss';

const SignPostCentered = ({ data, style, innerRef }) => (
  <section className="signpost-centered">
    <div className="wrapper">
      <div className="signpost-centered-content" style={style} ref={innerRef}>
        {data.signPostCenteredList.heading ? (
          <h3>{data.signPostCenteredList.heading}</h3>
        ) : null}
        {data.signPostCenteredList.description ? (
          <div>{parse(data.signPostCenteredList.description)}</div>
        ) : null}
        {data.signPostCenteredList.ctaLink ? (
          <Link to={data.signPostCenteredList.ctaLink}>
            <span>{data.signPostCenteredList.ctaText}</span>
            <RightArrowIcon />
          </Link>
        ) : null}
      </div>
    </div>
  </section>
);

SignPostCentered.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(SignPostCentered);
