import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';

import './styles.scss';

class CarrierPromo extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.count = 0;
    this.state = {
      suggestions: [],
      text: '',
      slug: '',
    };
  }

  /*
    handleButtonClick: functionality when see carriers button is clicked
  */
  handleButtonClick = () => {
    const { slug } = this.state;
    const { states } = this.props;
    const url = slug
      .toLowerCase()
      .split(' ')
      .join('-');
    this.setState(() => ({
      suggestions: [],
    }));
    for (let i = 0; i < states.edges.length; i += 1) {
      if (url === states.edges[i].node.stateSlug) {
        navigate(`/carriers/${url}`);
      }
      if (url.length === 0) {
        navigate('/carriers');
      }
    }
  };

  /*
    handleInputChange: Executes on input box when changed
  */
  handleInputChange = () => {
    const { states } = this.props;
    const inputValue = this.inputRef.current.value;
    let suggestions = [];
    if (inputValue.length > 0) {
      const regex = new RegExp(`${inputValue}`, 'i');
      suggestions = states.edges
        .sort()
        .filter((item) => regex.test(item.node.stateTitle));
    }
    this.setState(() => ({ suggestions, text: inputValue, slug: inputValue }));
  };

  /*
    handleKeyDown: Executes on input box when changed
  */
  handleKeyDown = (e) => {
    const keycode = e.keyCode;
    if (keycode === 13) {
      this.handleButtonClick();
    }
  };

  /*
    suggestionSelected: Selects suggestions and populates data to input box
  */
  suggestionSelected(value) {
    this.setState(() => ({
      text: value,
      slug: value,
      suggestions: [],
    }));
  }

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.slice(0, 5).map((item) => (
          <li
            key={item.node.id}
            onClick={() => this.suggestionSelected(item.node.stateTitle)}
            onKeyDown={null}
          >
            {item.node.stateTitle}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { data, style, innerRef } = this.props;
    const { text } = this.state;

    return (
      <section
        className="carrier-promo"
        style={
          data.backgroundColor
            ? { backgroundColor: data.backgroundColor.hex }
            : { backgroundColor: '#00BAC7' }
        }
      >
        <div className="wrapper">
          <div className="carrier-promo-content" style={style} ref={innerRef}>
            <div className="carrier-promo-title">
              {data.title ? <h2>{parse(data.title)}</h2> : null}
            </div>
            <div className="carrier-promo-input-area">
              <input
                value={text}
                type="text"
                placeholder="Enter your state here"
                ref={this.inputRef}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
              />
              {this.renderSuggestions()}
              <button type="button" onClick={this.handleButtonClick}>
                <span>See carriers</span>
                <RightArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

CarrierPromo.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  states: PropTypes.objectOf(PropTypes.array),
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

CarrierPromo.defaultProps = {
  states: PropTypes.undefined,
};

export default AnimateIn(CarrierPromo);
