import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import { PrimaryButton } from '../elements/buttons';

import './styles.scss';

const RelatedArticle = ({ data, style, innerRef }) => (
  <section className="related-article">
    {data ? (
      <div className="wrapper">
        <div>{parse(data.title)}</div>
        <ul className="related-article-list">
          {data.selectRelatedArticle
            ? data.selectRelatedArticle.map((item) => (
                <li key={item.id} style={style} ref={innerRef}>
                  {item.featuredImage ? (
                    <figure>
                      <img
                        src={item.featuredImage.url}
                        alt={item.featuredImage.alt}
                      />
                    </figure>
                  ) : null}
                  <div className="article-tag">
                    <span className="tag">{item.selectArticleTagCategory.articleTagTitle}</span>
                    <span>{item.meta.createdAt}</span>
                  </div>
                  <h4 className="article-heading">{item.title}</h4>
                  <PrimaryButton
                    text="Read more"
                    link={`/${item.selectArticleTagCategory.slug}/${item.slug}`}
                    icon
                  />
                </li>
              ))
            : null}
        </ul>
      </div>
    ) : null}
  </section>
);

RelatedArticle.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(RelatedArticle);
