import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { PrimaryButton } from '../elements/buttons';
import AnimateIn from '../animate-in';
import ContactContext from '../../context/ContactContext';

import './style.scss';

const SignPost = ({ data, style, innerRef }) => {
  const signData = get(data, 'signPostDefaultList');

  return (
    <ContactContext.Consumer>
      {({ toggleModal }) => (
        <section className="default-signpost">
          {signData ? (
            <div className="wrapper" ref={innerRef} style={style}>
              {signData.heading ? <h3>{signData.heading}</h3> : null}
              {signData.ctaText ? (
                <PrimaryButton
                  text={signData.ctaText}
                  link={signData.ctaLink}
                  icon
                  onClick={() => {
                    toggleModal();
                  }}
                />
              ) : null}
            </div>
          ) : null}
        </section>
      )}
    </ContactContext.Consumer>
  );
};

SignPost.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(SignPost);
