import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import VectorIcon from '../../../assets/svgs/Vector.svg';
import CloseVector from '../../../assets/svgs/close-vector.svg';
import RightArrowIcon from '../../../assets/svgs/icon-chevron-right.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

const Plandetailmodal = ({ data, val, closeModal }) => {
  const value = val;
  const modalSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: Number(value),
    dots: false,
    arrows: true,
  };

  return (
    <div className="plan-modal" id="modal-container">
      <div className="modal-content">
        <ul className="modal-list plan-card-container">
          <Slider {...modalSettings}>
            {data.planLink.map((item) => (
              <li className="plan-list-item">
                <div className="plan-content-container">
                  <div className="plan-content">
                    {item.planDescription ? (
                      <div className="plan-description">
                        {parse(item.planDescription)}
                      </div>
                    ) : null}
                    {item.modalPlanDetailCtaLink ? (
                      <a
                        className="select-plan-modal"
                        href={item.modalPlanDetailCtaLink}
                        title={item.modalPlanDetailCtaText}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {item.modalPlanDetailCtaText}
                          <RightArrowIcon />
                        </span>
                      </a>
                    ) : null}
                    {item.modalContactUsCtaLink ? (
                      <Link
                        className="contact-us-cta"
                        onClick={() => closeModal()}
                        to={item.modalContactUsCtaLink}
                        title={item.modalContactUsCtaText}
                      >
                        <span>
                          {item.modalContactUsCtaText}
                          <RightArrowIcon />
                        </span>
                      </Link>
                    ) : null}
                  </div>
                  <div className="plan-detail-content">
                    <h5 className="plan-rate mobile-title">
                      {item.rateTitleForModel}
                    </h5>
                    <div className="presonal-line">
                      <h5 className="list-heading">{item.personalLineTitle}</h5>
                      <div className="percent-list-container">
                        {item.personalRate.map((key) => (
                          <div className="percent-list">
                            <span className="percent">
                              {key.personalRateValue}
                            </span>
                            <span className="precent-text">
                              {key.personalRateCategory}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="commercial-line">
                      <h5 className="list-heading">
                        {item.commercialLinesTitle}
                      </h5>
                      <div className="percent-list-container">
                        {item.commercialLine.map((key) => (
                          <div className="percent-list">
                            <span className="percent">
                              {key.commercialRate}
                            </span>
                            <span className="precent-text">
                              {key.commercialRateCategory}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="feature-list">
                      <h5 className="feature-title mobile-title">
                        {item.featureTitleForModal}
                      </h5>
                      <ul>
                        {item.featuresList.map((key) => (
                          <div
                            className={`feature-heading ${
                              key.featureAvailable
                                ? 'feature-list-item'
                                : 'feature-list-item mobile-not-available'
                            }`}
                          >
                            <div
                              className={`feature-heading ${
                                key.featureAvailable
                                  ? 'available'
                                  : 'not-available'
                              }`}
                            >
                              <span>{key.featureTitle}</span>
                              {key.featureAvailable ? (
                                <VectorIcon />
                              ) : (
                                <CloseVector />
                              )}
                            </div>
                            <span className="feature-content">
                              {key.featureValue}
                            </span>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </Slider>
        </ul>
      </div>
    </div>
  );
};

Plandetailmodal.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  val: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Plandetailmodal;
