/* eslint-disable react/require-default-props */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import { get } from 'lodash';

// Assets
import DownloadIcon from '../../../assets/svgs/icon-download.svg';
import RightArrowIcon from '../../../assets/svgs/icon-chevron-right.svg';

import AnimateIn from '../../animate-in';

// Utilities
import FileUtils from '../../../utils/file-utils';
import ListingUtils from '../../../utils/listing-utils';

import './style.scss';

const TOKEN_QUERY = graphql`
  query siteMeta {
    site {
      siteMetadata {
        datoToken
      }
    }
  }
`;

const ListItem = ({
  image,
  mImage,
  title,
  previewTitle,
  description,
  ctaText,
  date,
  articleLink,
  fileUrl,
  size,
  fileType,
  tag,
  style,
  views,
  recordId,
  innerRef,
}) => (
  <StaticQuery
    query={TOKEN_QUERY}
    render={(data) => {
      const TOKEN = get(data, 'site.siteMetadata.datoToken');
      // eslint-disable-next-line no-nested-ternary
      const showTitle = previewTitle ? (
        <h2 className="article-title">{previewTitle}</h2>
      ) : title ? (
        <h2 className="article-title">{title}</h2>
      ) : null;
      // File component
      if (fileUrl) {
        // File size
        const fileSize = FileUtils.bytesToSize(size);
        return (
          // The style prop is the inline style passed from animate in for animated reveal
          <div className="download-item" ref={innerRef} style={style}>
            <h2 className="title">{title}</h2>
            <h2 className="title">{previewTitle}</h2>
            <div className="meta-wrapper">
              <span className="meta">
                {`Download ${fileSize} `}
                <span className="file-type">{fileType}</span>
              </span>
              <button
                className="download-button"
                type="button"
                onClick={() => {
                  ListingUtils.increaseViews(views, recordId, TOKEN);
                  FileUtils.download(fileUrl);
                }}
              >
                .
                <DownloadIcon />
              </button>
            </div>
          </div>
        );
      }
      // Article component
      return (
        // The style prop is the inline style passed from animate in for animated reveal
        <Link
          to={articleLink}
          className="articleitem"
          ref={innerRef}
          style={style}
        >
          {image ? (
            <figure className="article-image">
              <img
                src={mImage || image}
                alt={title}
                className="inner-img object-fit-image"
              />
            </figure>
          ) : null}
          {date || tag ? (
            <div className="info">
              {tag ? <span className="tag">{`${tag}`}</span> : null}
              {date ? <span>{`${date}`}</span> : null}
            </div>
          ) : null}
          {showTitle}
          {description ? (
            <div className="article-description">{parse(description)}</div>
          ) : null}
          {ctaText ? (
            <span className="article-cta">
              <span>{ctaText}</span> <RightArrowIcon />
            </span>
          ) : null}
        </Link>
      );
    }}
  />
);

// Proptypes
ListItem.propTypes = {
  image: PropTypes.arrayOf(PropTypes.object).isRequired,
  mImage: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.arrayOf(PropTypes.object).isRequired,
  ctaText: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.arrayOf(PropTypes.object).isRequired,
  articleLink: PropTypes.arrayOf(PropTypes.object).isRequired,
  description: PropTypes.string,
  fileUrl: PropTypes.string,
  size: PropTypes.number,
  fileType: PropTypes.string,
  tag: PropTypes.string,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }),
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
  views: PropTypes.number,
  recordId: PropTypes.string,
  previewTitle: PropTypes.string,
};

// Default Props
ListItem.defaultProps = {
  previewTitle: '',
  description: '',
  fileUrl: '',
  size: 0,
  fileType: '',
  tag: '',
  style: {},
  views: 0,
  recordId: '',
};

export default AnimateIn(ListItem);
