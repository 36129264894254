import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import { PrimaryButton } from '../elements/buttons';

import './styles.scss';

const FeatureCta2 = ({ data, style, innerRef }) => (
  <section className="feature-cta2">
    <div className="wrapper">
      <div className="feature-cta-content" style={style} ref={innerRef}>
        <div className="feature-cta-head">
          {data.title ? <h3 className="section-title">{data.title}</h3> : null}
          {data.subtitle ? (
            <div className="section-description">
              <h6>{parse(data.subtitle)}</h6>
            </div>
          ) : null}
        </div>
        <div className="feature-cta-flex-container">
          {Array.isArray(get(data, 'featuresItem.featuresList'))
            ? data.featuresItem.featuresList.map((item) => (
                <div
                  className="feature-cta-flexbox"
                  key={item.id}
                  style={
                    data.featuresItem.featuresList.length < 2
                      ? {
                          width: `${100 /
                            data.featuresItem.featuresList.length}%`,
                        }
                      : null
                  }
                >
                  <div className="feature-cta-flex-content">

                      <div className="image-title">
                        {item.featureIcon ? (
                          <figure>
                            <img src={item.featureIcon.url} alt="Features" />
                          </figure>
                        ) : null}

                        {item.featureTitle ? (
                          <h4>{item.featureTitle}</h4>
                        ) : null}
                      </div>
                      {item.featureDescription ? (
                        <div className="feature-description">
                          {parse(item.featureDescription)}
                        </div>
                      ) : null}
                      {item.featureCtaText ? (
                        <PrimaryButton
                          text={item.featureCtaText}
                          link={item.featureCtaLink}
                          color="primary-navy"
                          icon
                        />
                      ) : null}

                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  </section>
);

FeatureCta2.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(FeatureCta2);
