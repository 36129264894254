import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';

import './styles.scss';

const IframeWrapper = ({ data, style, innerRef }) => {
  // Component props
  const ComponentProps = {
    backgroundColor: get(data, 'backgroundColor.hex'),
    embedLink: get(data, 'embedLink'),
  };

  //   Custom style
  const customStyle = {
    ...(ComponentProps.backgroundColor && {
      backgroundColor: ComponentProps.backgroundColor,
    }),
  };

  // Return if url is not present
  if (!ComponentProps.embedLink) return;

  return (
    <div className="iframe" style={{ ...style, ...customStyle }} ref={innerRef}>
      <div className="wrapper">
        <iframe
          src={ComponentProps.embedLink}
          height="800"
          title="Job portal"
        />
      </div>
    </div>
  );
};

IframeWrapper.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }),
  innerRef: PropTypes.objectOf(PropTypes.object),
};

// Default props
IframeWrapper.defaultProps = {
  style: {},
  innerRef: {}
};

export default IframeWrapper;
