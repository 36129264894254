import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import { PrimaryButton } from '../elements/buttons';

import './styles.scss';

const IntroCta = ({ data, style, innerRef }) => {
  const componentProps = {
    title: get(data, 'intro'),
    ctaLink: get(data, 'introCtaLink'),
    ctaText: get(data, 'introCtaText'),
  };
  return (
    <section className="intro-cta">
      <div className="wrapper">
        <div className="intro-cta-content" style={style} ref={innerRef}>
          {componentProps.title ? parse(componentProps.title) : null}
          {componentProps.ctaText ? (
            <PrimaryButton
              text={componentProps.ctaText}
              link={componentProps.ctaLink}
              icon
              color="primary-cyan"
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

IntroCta.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(IntroCta);
