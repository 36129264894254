import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { get } from 'lodash';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';

import './styles.scss';

const LinkPromo = ({ data, style, innerRef }) => {
  const linksData = get(data, 'linkOption.linkPromo');
  const backgroundColor = get(data, 'backgroundColor');

  // Block Min height
  const [minHeight, setMinHeight] = useState();
  const promoListRef = Array(linksData.length)
    .fill()
    .map(() => useRef());

  useEffect(() => {
    // Set min height of each block to set have height
    if (Array.isArray(promoListRef)) {
      const sortedArray = promoListRef.sort((a, b) => {
        if (b.current && a.current) {
          return b.current.clientHeight - a.current.clientHeight;
        }
      });
      setMinHeight(get(sortedArray[0], 'current.clientHeight'));
    }
  }, [linksData.length]);

  return (
    <section
      className="link-promo"
      style={
        backgroundColor
          ? { backgroundColor: backgroundColor.hex }
          : { backgroundColor: 'transparent' }
      }
    >
      <div className="wrapper">
        <div className="link-promo-content">
          <div className="link-promo-flex-container">
            {Array.isArray(linksData)
              ? linksData.map((item, index) => (
                  <div
                    className="link-promo-flexbox"
                    key={item.id}
                    style={style}
                    ref={innerRef}
                  >
                    <div
                      className="link-promo-flex-content"
                      ref={promoListRef[index]}
                      style={
                        minHeight >= 0 ? { minHeight: `${minHeight}px` } : null
                      }
                    >
                      <h4>{item.title}</h4>
                      <div>
                        {parse(item.description)}
                        <Link to={item.link}>
                          <RightArrowIcon />
                          .
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </section>
  );
};

LinkPromo.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(LinkPromo);
