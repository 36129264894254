import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import './styles.scss';

const FeaturesBolt = ({ data, style, innerRef }) => (
  <section className="features-bolt">
    <div className="wrapper">
      <div className="features-content" style={style} ref={innerRef}>
        <div className="content-box">
          {data.title ? <p className="title">{data.title}</p> : null}
          <div className="features-flex-container">
            {Array.isArray(data.featureItemLink.featuresItem)
              ? data.featureItemLink.featuresItem.map((item) => (
                  <div className="features-flexbox" key={item.id}>
                    <div className="features-flex-content">
                      {item.itemIcon ? (
                        <img src={item.itemIcon.url} alt="Features" />
                      ) : null}
                      {item.featureItemTitle ? (
                        <h5>{item.featureItemTitle}</h5>
                      ) : null}
                      {item.featureItemDescription ? (
                        <div className="description">
                          {parse(item.featureItemDescription)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      {data.featureBackgroundImage ? (
        <img
          src={data.featureBackgroundImage.url}
          alt="Features Background"
          className="bg-image"
        />
      ) : null}
    </div>
  </section>
);

FeaturesBolt.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(FeaturesBolt);
