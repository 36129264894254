import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { get } from 'lodash';

import AnimateIn from '../animate-in';

import './styles.scss';
import { PrimaryButton } from '../elements/buttons';

const SignPostImage = ({ data, style, innerRef }) => {
  const featuresData = get(data, 'signPostImageList');

  const componentProps = {
    title: get(featuresData, 'signTitle'),
    subtitle: get(featuresData, 'signSubTitle'),
    description: get(featuresData, 'signDecription'),
    ctaText: get(featuresData, 'signCtaText'),
    ctaLink: get(featuresData, 'signCtaLink'),
    imgUrl: get(featuresData, 'signImage.url'),
  };

  return (
    <section className="signpost-image">
      <div className="wrapper">
        <div className="features-content">
          <div className="content-box">
            {componentProps.title ? (
              <p className="title" style={style} ref={innerRef}>
                {componentProps.title}
              </p>
            ) : null}
            {componentProps.subtitle ? (
              <span className="subtitle" style={style} ref={innerRef}>
                {componentProps.subtitle}
              </span>
            ) : null}
            {componentProps.description ? (
              <div
                className="features-description"
                style={style}
                ref={innerRef}
              >
                {parse(componentProps.description)}
              </div>
            ) : null}
            {componentProps.ctaText ? (
              <PrimaryButton
                text={componentProps.ctaText}
                link={componentProps.ctaLink}
                color="primary-cyan"
                icon
                style={style}
                ref={innerRef}
              />
            ) : null}
          </div>
        </div>
        {componentProps.imgUrl ? (
          <img
            src={componentProps.imgUrl}
            alt="Features Background"
            className="bg-image"
            style={style}
            ref={innerRef}
          />
        ) : null}
      </div>
    </section>
  );
};

SignPostImage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(SignPostImage);
