import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import AnimateIn from '../animate-in';

import DownArrowIcon from '../../assets/svgs/icon-chevron-down.svg';

import './styles.scss';

const handleAccordionClick = (e) => {
  const element = e.target;
  if (element.classList.contains('active')) {
    element.classList.remove('active');
    element.nextSibling.classList.add('hide-data');
  } else {
    element.classList.add('active');
    element.nextSibling.classList.remove('hide-data');
  }
};

const Accordion = ({ data, style, innerRef }) => (
  <section className="accordion" ref={innerRef} style={style}>
    <div className="wrapper">
      <div className="accordion-content">
        {data.title ? <h3>{data.title}</h3> : null}
        {Array.isArray(data.faqList.faqList)
          ? data.faqList.faqList.map((item, index) => {
              const isFirstEle = index === 0;
              return (
                <div
                  className="accordion-area"
                  key={item.id}
                  ref={innerRef}
                  style={style}
                >
                  <div
                    role="button"
                    tabIndex={0}
                    className={`accordion-title ${isFirstEle ? 'active' : ''}`}
                    onClick={handleAccordionClick}
                    onKeyPress={null}
                  >
                    <p>{item.title}</p>
                    <DownArrowIcon />
                  </div>
                  <div
                    className={`accordion-data ${
                      isFirstEle ? '' : 'hide-data'
                    }`}
                  >
                    <div className="accordion-data-section">
                      <div className="accordion-data-content">
                        <div className="rich-text">{parse(item.body)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  </section>
);

Accordion.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(Accordion);
