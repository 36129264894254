/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { get, filter, find } from 'lodash';

import RightArrowIcon from '../../assets/svgs/icon-chevron-right.svg';
import AnimateIn from '../animate-in';
import './styles.scss';

// Filter line category on basis of category id
const getFilteredLineCategory = (lineCategoryList = [], categoryId) => {
  let carrierCount = 0;
  const filteredList = filter(lineCategoryList, (eachLineCategory) => {
    // Parent category i.e Personal / Commercial line
    const filterCategory = get(
      eachLineCategory,
      'lineFilter.selectCarrierCategory'
    );

    const isMatchingCategory = find(filterCategory, ['originalId', categoryId]);

    // Count selected carriers
    if (isMatchingCategory) {
      // Carrier attached
      const carrierSelected = get(eachLineCategory, 'carriers');
      carrierCount += carrierSelected.length;
    }
    // Filter if carrier is not present or is not match to the parent category
    return isMatchingCategory && get(eachLineCategory, 'carriers').length;
  });

  return {
    filteredLineCategory: filteredList || [],
    carrierCount,
  };
};

// Get a list of all the unique categories selected in the line category lis
const getUniqueCategoryFilters = (list = []) => {
  let uniqueCategoryFilters = [];
  // Loop through all attached filters for a state
  for (let i = 0; i < list.length; i++) {
    const currentLineCategory = list[i];
    //  Parent category
    const filterCategory = get(
      currentLineCategory,
      'lineFilter.selectCarrierCategory'
    );

    // Loop through all category filter that are attached to a line filter
    for (let j = 0; j < filterCategory.length; j++) {
      const currentCategory = filterCategory[j];
      // Check if the category has already been added in the unique category filter array
      const checkIfCategoryExists = find(uniqueCategoryFilters, [
        'originalId',
        currentCategory.originalId,
      ]);
      if (!checkIfCategoryExists) {
        // Add only if category is not present in unique array
        uniqueCategoryFilters = [...uniqueCategoryFilters, currentCategory];
      }
    }
  }
  return uniqueCategoryFilters;
};

// Get carrier count from filter data
const getCarrierCount = (list = []) => {
  const categoryFilters = getUniqueCategoryFilters(list);
  let stateCarrierCount = 0;
  for (let i = 0; i < categoryFilters.length; i++) {
    const { carrierCount } = getFilteredLineCategory(
      list,
      get(categoryFilters[i], 'originalId')
    );
    stateCarrierCount = +carrierCount;
  }

  return stateCarrierCount;
};

const StatesPromo = ({ data, style, innerRef }) => (
  <div>
    {Array.isArray(data.statePromoList) && data.statePromoList.length ? (
      <section className="states-promo">
        <div className="wrapper">
          <div className="states-promo-content">
            {data.statePromoTitle ? <h3>{data.statePromoTitle}</h3> : null}
            <div className="states-promo-flex-container">
              {Array.isArray(data.statePromoList)
                ? data.statePromoList.map((item) => {
                    const carrierCount = getCarrierCount(item.categoryFilter);
                    return (
                      <div
                        className="states-promo-flexbox"
                        key={item.id}
                        style={style}
                        ref={innerRef}
                      >
                        <div className="states-promo-flex-content">
                          {item.stateTitle ? <h4>{item.stateTitle}</h4> : null}
                          <Link to={`/carriers/${item.stateSlug}`}>
                            <RightArrowIcon />
                          </Link>
                          {carrierCount >= 0 ? (
                            <p className="carriers-count">
                              {carrierCount}
                              <span>Carriers</span>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </section>
    ) : null}
  </div>
);

StatesPromo.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(StatesPromo);
