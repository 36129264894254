/* eslint-disable react/jsx-boolean-value */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// Component
import ListItem from '../elements/list-item';
import { SecondaryButton } from '../elements/buttons';

// Styles
import './styles.scss';

const ListContainer = ({ list, loadMore, handleLoadMore, type }) => {
  const sortListByDateTimeDescending = (dateString) => Date.parse(dateString);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };
  list.sort((a, b) => {
    const dateA = sortListByDateTimeDescending(a?.meta?.createdAt);
    const dateB = sortListByDateTimeDescending(b?.meta?.createdAt);
    return dateB - dateA;
  });
  const List = list.map((eachItem, index) => {
    const categoryUrl = get(eachItem, 'selectArticleTagCategory.slug');
    const articleUrl = get(eachItem, 'slug');
    const url = `/${categoryUrl}/${articleUrl}`;
    const formattedDate = formatDate(eachItem?.meta?.createdAt);
    if (type === 'featured') {
      const width = 100 / (list.length - 1);

      return (
        <ListItem
          title={get(eachItem, 'title')}
          previewTitle={get(eachItem, 'previewTitle')}
          ctaText="Read more"
          image={get(eachItem, 'featuredImage.url')}
          mImage={get(eachItem, 'featuredImageMobile.url')}
          description={get(eachItem, 'subtitle')}
          date={formattedDate}
          tag={get(eachItem, 'selectArticleTagCategory.articleTagTitle')}
          articleLink={url}
          size={get(eachItem, 'uploadFile.size')}
          fileType={get(eachItem, 'uploadFile.format')}
          fileUrl={get(eachItem, 'uploadFile.url')}
          views={get(eachItem, 'views')}
          recordId={get(eachItem, 'originalId')}
          key={get(eachItem, 'id')}
          style={{
            flexBasis: index === 0 ? '100%' : `calc(${width}% - 30px)`,
          }}
        />
      );
    }

    return (
      <ListItem
        title={get(eachItem, 'title')}
        previewTitle={get(eachItem, 'previewTitle')}
        ctaText="Read more"
        image={get(eachItem, 'featuredImage.url')}
        mImage={get(eachItem, 'featuredImageMobile.url')}
        description={get(eachItem, 'subtitle')}
        date={formattedDate}
        tag={get(eachItem, 'selectArticleTagCategory.articleTagTitle')}
        articleLink={url}
        size={get(eachItem, 'uploadFile.size')}
        fileType={get(eachItem, 'uploadFile.format')}
        fileUrl={get(eachItem, 'uploadFile.url')}
        views={get(eachItem, 'views')}
        recordId={get(eachItem, 'originalId')}
        key={get(eachItem, 'id')}
      />
    );
  });

  return (
    <div className="list-container">
      {List}
      {loadMore ? (
        <SecondaryButton
          text="load more"
          onClick={handleLoadMore}
          link="/"
          type="type-1"
          isBtn={true}
        />
      ) : null}
    </div>
  );
};

// Proptypes
ListContainer.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  loadMore: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  type: PropTypes.string,
};

// Default props
ListContainer.defaultProps = {
  list: [],
  loadMore: false,
  handleLoadMore: () => {},
  type: '',
};

export default ListContainer;
