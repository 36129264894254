const PROTO = {
  /**
   * Convert file size from bytes.
   *
   */
  bytesToSize: (bytes, seperator = '') => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
  },

  /**
   * Download file
   *
   */
  download: (file) => {
    // exit if no location is present
    if (!file) {
      return;
    }
    const link = document.createElement('a');
    link.dataType = 'json';
    link.href = file;
    link.target = '_blank';
    link.download = 'Brochure.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  },
};

const FileUtils = Object.create(PROTO);

export default FileUtils;
